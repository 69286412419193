import { useRef, useState, useEffect, useContext, useLayoutEffect, LegacyRef } from "react";
import {ITeachingBubbleStyles, TeachingBubble } from "@fluentui/react";
import { AppStateContext } from "../../state/AppProvider";
import { IntroductionTutorialSteps } from "../../constants/chatHistory";
import { Step, setTutorialProgress } from "../../api";
import { useBoolean } from "@fluentui/react-hooks";
import { useOktaAuth } from "@okta/okta-react";


const TeachingBubbleSteps = () => {

    const appStateContext = useContext(AppStateContext)
    const { authState, oktaAuth } = useOktaAuth(); 
    const [currentStep,setCurrentStep] = useState<Step>();
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(true);
    const [disableNextButton, setdisableNextButton]= useState<boolean>(false);
    const [disablePreviousButton, setDisablePreviousButton] = useState<boolean>(true);
    const [btnLabel,setBtnLabel] = useState<string>("Next");

    //styles for teaching bubble
    const customStylesTeachingBubble: Partial<ITeachingBubbleStyles> = {
        subText:{
            fontSize: 14,
            lineHeight: '1.2rem'
        },
        headline:{
            fontWeight: 400
        }
    }

    useEffect(()=>{
        //get step
        const step = IntroductionTutorialSteps.steps.find((e)=> e.step == appStateContext?.state.step)
        
        if(step?.step != 0){
            setBtnLabel("Next");
            setDisablePreviousButton(false);
            setdisableNextButton(false);
        }

        if(step?.step == 0)
            setDisablePreviousButton(true);

        if(step?.step == 2)
            setBtnLabel("Finish");

        setCurrentStep(step);

    },[appStateContext?.state.step])

    const nextStep = async() => {
        if(currentStep != undefined){
            if(currentStep.step === 2){
                toggleTeachingBubbleVisible;
                const accessToken = await oktaAuth.getAccessToken();
                let response = await setTutorialProgress(appStateContext?.state.userProfile, accessToken)
                 if(!response){
                    console.log("something went wrong!")
                 }
                 appStateContext?.dispatch({type:'SHOW_TUTORIAL'});
                 
            }else{
                appStateContext?.dispatch({type: 'NEXT_STEP', payload: currentStep.step + 1})
            }
        }
    }

    const previousStep = () => {
        if(currentStep != undefined){
            appStateContext?.dispatch({type: 'PREVIOUS_STEP', payload: currentStep.step -  1})
        }

    }

    return(
        <TeachingBubble
            target={`#${currentStep?.id}`}
            primaryButtonProps={{text:"Previous", onClick: previousStep,  disabled:disablePreviousButton}}
            secondaryButtonProps={{text: btnLabel, onClick: nextStep, disabled: disableNextButton}}
            onDismiss={toggleTeachingBubbleVisible}
            headline={currentStep?.title}
            styles={customStylesTeachingBubble}
            >
                {currentStep?.description}
        </TeachingBubble>
    )
}

export default TeachingBubbleSteps;