import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, useNavigate  } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";

//Okta Configuration
import config from "./config";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import LoginCallback from "./components/okta/LoginCallback";
import Loading from "./components/okta/Loading";
initializeIcons();

const oktaAuth = new OktaAuth({
    ...config.oidc,
    redirectUri: window.location.origin + "/"
});

export default function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        let uri = "/";
        if (originalUri) {
            uri = originalUri;
        }
        navigate(toRelativeUrl(uri, window.location.origin));
    };
    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path="/" element={<LoginCallback homePath="/chat" loadingElement={<Loading />} />} />
                <Route path="/chat" element={<Layout />}>
                    <Route path="" element={<Chat />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </Security>
        
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AppStateProvider>
            <HashRouter>
                <App />
            </HashRouter>
        </AppStateProvider>
    </React.StrictMode>

);
