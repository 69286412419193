import { Assistant, Conversation, Steps } from "../api/models";

export const chatHistorySampleData: Conversation[] = [
    {
      "id": "1",
      "title": "Customer Support Issue And Resolution and Customer Support",
      "messages": [
        {
          "id": "1",
          "role": "user",
          "content": "How can I reset my password?",
          "date": "2023-08-10T08:00:00Z"
        },
        {
          "id": "2",
          "role": "assistant",
          "content": "To reset your password, please visit the 'Forgot Password' page and follow the instructions.",
          "date": "2023-08-10T08:05:00Z"
        }
      ],
      "date": "2023-08-10T07:55:00Z"
    },
    {
      "id": "2",
      "title": "Order Inquiry",
      "messages": [
        {
          "id": "3",
          "role": "user",
          "content": "Can you provide an update on my order?",
          "date": "2023-07-09T15:30:00Z"
        },
        {
          "id": "4",
          "role": "assistant",
          "content": "Sure! Let me check the status of your order. Please provide me with your order number.",
          "date": "2023-07-09T15:32:00Z"
        },
        {
          "id": "5",
          "role": "user",
          "content": "My order number is 12345.",
          "date": "2023-07-09T15:35:00Z"
        },
        {
          "id": "6",
          "role": "assistant",
          "content": "Thank you. I'm looking up the details for order #12345. Please wait a moment.",
          "date": "2023-07-09T15:40:00Z"
        }
      ],
      "date": "2023-07-09T15:25:00Z"
    },
    {
      "id": "3",
      "title": "August Entry 1",
      "messages": [
        {
          "id": "7",
          "role": "user",
          "content": "I have a question about my subscription.",
          "date": "2022-08-20T12:30:00Z"
        },
        {
          "id": "8",
          "role": "assistant",
          "content": "Of course! Feel free to ask your question, and I'll be happy to help.",
          "date": "2022-08-20T12:35:00Z"
        }
      ],
      "date": "2022-08-20T12:25:00Z"
    },
    {
      "id": "4",
      "title": "August Entry 2",
      "messages": [
        {
          "id": "9",
          "role": "user",
          "content": "When will my order be delivered?",
          "date": "2023-08-15T09:45:00Z"
        },
        {
          "id": "10",
          "role": "assistant",
          "content": "I can check the delivery status for you. Please provide your order number.",
          "date": "2023-08-15T09:50:00Z"
        }
      ],
      "date": "2023-08-15T09:40:00Z"
    },
    {
      "id": "5",
      "title": "June Entry 1",
      "messages": [
        {
          "id": "11",
          "role": "user",
          "content": "Is the product available in different colors?",
          "date": "2023-06-05T16:20:00Z"
        },
        {
          "id": "12",
          "role": "assistant",
          "content": "Yes, the product is available in blue, red, and green.",
          "date": "2023-06-05T16:25:00Z"
        }
      ],
      "date": "2023-06-05T16:15:00Z"
    },
    {
        "id": "133",
        "title": "Order Inquiry",
        "messages": [
          {
            "id": "1",
            "role": "user",
            "content": "How can I reset my password?",
            "date": "2023-08-10T08:00:00Z"
          },
          {
            "id": "2",
            "role": "assistant",
            "content": "To reset your password, please visit the 'Forgot Password' page and follow the instructions.",
            "date": "2023-08-10T08:05:00Z"
          }
        ],
        "date": "2023-08-10T07:55:00Z"
      },
      {
        "id": "233",
        "title": "Phone Plan System Reset",
        "messages": [
          {
            "id": "3",
            "role": "user",
            "content": "Can you provide an update on my order?",
            "date": "2023-07-09T15:30:00Z"
          },
          {
            "id": "4",
            "role": "assistant",
            "content": "Sure! Let me check the status of your order. Please provide me with your order number.",
            "date": "2023-07-09T15:32:00Z"
          },
          {
            "id": "5",
            "role": "user",
            "content": "My order number is 12345.",
            "date": "2023-07-09T15:35:00Z"
          },
          {
            "id": "6",
            "role": "assistant",
            "content": "Thank you. I'm looking up the details for order #12345. Please wait a moment.",
            "date": "2023-07-09T15:40:00Z"
          }
        ],
        "date": "2023-07-09T15:25:00Z"
      },
      {
        "id": "333",
        "title": "System Failure Error",
        "messages": [
          {
            "id": "7",
            "role": "user",
            "content": "I have a question about my subscription.",
            "date": "2022-08-20T12:30:00Z"
          },
          {
            "id": "8",
            "role": "assistant",
            "content": "Of course! Feel free to ask your question, and I'll be happy to help.",
            "date": "2022-08-20T12:35:00Z"
          }
        ],
        "date": "2022-08-20T12:25:00Z"
      },
      {
        "id": "433",
        "title": "Keyboard Coverage",
        "messages": [
          {
            "id": "9",
            "role": "user",
            "content": "Can you help me fix my keyboard?",
            "date": "2023-08-15T09:45:00Z"
          },
          {
            "id": "10",
            "role": "assistant",
            "content": "Sure. What seems to be the issue?",
            "date": "2023-08-15T09:50:00Z"
          }
        ],
        "date": "2023-08-15T09:40:00Z"
      },
      {
        "id": "533",
        "title": "Color Difference",
        "messages": [
          {
            "id": "11",
            "role": "user",
            "content": "Is the product available in different colors?",
            "date": "2023-06-05T16:20:00Z"
          },
          {
            "id": "12",
            "role": "assistant",
            "content": "Yes, the product is available in blue, red, and green.",
            "date": "2023-06-05T16:25:00Z"
          }
        ],
        "date": "2023-06-05T16:15:00Z"
      },

      {
        "id": "1111",
        "title": "Customer Support Issue And Resolution and Customer Support",
        "messages": [
          {
            "id": "1",
            "role": "user",
            "content": "How can I reset my password?",
            "date": "2023-08-10T08:00:00Z"
          },
          {
            "id": "2",
            "role": "assistant",
            "content": "To reset your password, please visit the 'Forgot Password' page and follow the instructions.",
            "date": "2023-08-10T08:05:00Z"
          }
        ],
        "date": "2023-08-10T07:55:00Z"
      },
      {
        "id": "21122",
        "title": "Order Inquiry",
        "messages": [
          {
            "id": "3",
            "role": "user",
            "content": "Can you provide an update on my order?",
            "date": "2023-07-09T15:30:00Z"
          },
          {
            "id": "4",
            "role": "assistant",
            "content": "Sure! Let me check the status of your order. Please provide me with your order number.",
            "date": "2023-07-09T15:32:00Z"
          },
          {
            "id": "5",
            "role": "user",
            "content": "My order number is 12345.",
            "date": "2023-07-09T15:35:00Z"
          },
          {
            "id": "6",
            "role": "assistant",
            "content": "Thank you. I'm looking up the details for order #12345. Please wait a moment.",
            "date": "2023-07-09T15:40:00Z"
          }
        ],
        "date": "2023-07-09T15:25:00Z"
      },
      {
        "id": "3111122",
        "title": "August Entry 1",
        "messages": [
          {
            "id": "7",
            "role": "user",
            "content": "I have a question about my subscription.",
            "date": "2022-08-20T12:30:00Z"
          },
          {
            "id": "8",
            "role": "assistant",
            "content": "Of course! Feel free to ask your question, and I'll be happy to help.",
            "date": "2022-08-20T12:35:00Z"
          }
        ],
        "date": "2022-08-20T12:25:00Z"
      },
      {
        "id": "4123344",
        "title": "August Entry 3",
        "messages": [
          {
            "id": "9",
            "role": "user",
            "content": "When will my order be delivered?",
            "date": "2023-08-15T09:45:00Z"
          },
          {
            "id": "10",
            "role": "assistant",
            "content": "I can check the delivery status for you. Please provide your order number.",
            "date": "2023-08-15T09:50:00Z"
          }
        ],
        "date": "2023-08-15T09:40:00Z"
      },
      {
        "id": "51121213",
        "title": "June Entry 1",
        "messages": [
          {
            "id": "11",
            "role": "user",
            "content": "Is the product available in different colors?",
            "date": "2023-06-05T16:20:00Z"
          },
          {
            "id": "12",
            "role": "assistant",
            "content": "Yes, the product is available in blue, red, and green.",
            "date": "2023-06-05T16:25:00Z"
          }
        ],
        "date": "2023-06-05T16:15:00Z"
      },
      {
          "id": "131443",
          "title": "Order Inquiry",
          "messages": [
            {
              "id": "1",
              "role": "user",
              "content": "How can I reset my password?",
              "date": "2023-08-10T08:00:00Z"
            },
            {
              "id": "2",
              "role": "assistant",
              "content": "To reset your password, please visit the 'Forgot Password' page and follow the instructions.",
              "date": "2023-08-10T08:05:00Z"
            }
          ],
          "date": "2023-08-10T07:55:00Z"
        },
        {
          "id": "2455633",
          "title": "Phone Plan System Reset",
          "messages": [
            {
              "id": "3",
              "role": "user",
              "content": "Can you provide an update on my order?",
              "date": "2023-07-09T15:30:00Z"
            },
            {
              "id": "4",
              "role": "assistant",
              "content": "Sure! Let me check the status of your order. Please provide me with your order number.",
              "date": "2023-07-09T15:32:00Z"
            },
            {
              "id": "5",
              "role": "user",
              "content": "My order number is 12345.",
              "date": "2023-07-09T15:35:00Z"
            },
            {
              "id": "6",
              "role": "assistant",
              "content": "Thank you. I'm looking up the details for order #12345. Please wait a moment.",
              "date": "2023-07-09T15:40:00Z"
            }
          ],
          "date": "2023-07-09T15:25:00Z"
        },
        {
          "id": "33567565673",
          "title": "System Failure Error",
          "messages": [
            {
              "id": "7",
              "role": "user",
              "content": "I have a question about my subscription.",
              "date": "2022-08-20T12:30:00Z"
            },
            {
              "id": "8",
              "role": "assistant",
              "content": "Of course! Feel free to ask your question, and I'll be happy to help.",
              "date": "2022-08-20T12:35:00Z"
            }
          ],
          "date": "2022-08-20T12:25:00Z"
        },
        {
          "id": "677433",
          "title": "International Coverage",
          "messages": [
            {
              "id": "9",
              "role": "user",
              "content": "When will my order be delivered?",
              "date": "2023-08-15T09:45:00Z"
            },
            {
              "id": "10",
              "role": "assistant",
              "content": "I can check the delivery status for you. Please provide your order number.",
              "date": "2023-08-15T09:50:00Z"
            }
          ],
          "date": "2023-08-15T09:40:00Z"
        },
        {
          "id": "578833",
          "title": "Color Difference",
          "messages": [
            {
              "id": "11",
              "role": "user",
              "content": "Is the product available in different colors?",
              "date": "2023-06-05T16:20:00Z"
            },
            {
              "id": "12",
              "role": "assistant",
              "content": "Yes, the product is available in blue, red, and green.",
              "date": "2023-06-05T16:25:00Z"
            }
          ],
          "date": "2023-06-05T16:15:00Z"
        },



        {
            "id": "34573",
            "title": "System Failure Error",
            "messages": [
              {
                "id": "7",
                "role": "user",
                "content": "I have a question about my subscription.",
                "date": "2022-08-20T12:30:00Z"
              },
              {
                "id": "8",
                "role": "assistant",
                "content": "Of course! Feel free to ask your question, and I'll be happy to help.",
                "date": "2022-08-20T12:35:00Z"
              }
            ],
            "date": "2022-08-20T12:25:00Z"
          },
          {
            "id": "402042",
            "title": "Details",
            "messages": [
              {
                "id": "9",
                "role": "user",
                "content": "When will my order be delivered?",
                "date": "2023-08-15T09:45:00Z"
              },
              {
                "id": "10",
                "role": "assistant",
                "content": "I can check the delivery status for you. Please provide your order number.",
                "date": "2023-08-15T09:50:00Z"
              }
            ],
            "date": "2023-08-15T09:40:00Z"
          },
          {
            "id": "03491200",
            "title": "Color Difference",
            "messages": [
              {
                "id": "11",
                "role": "user",
                "content": "Is the product available in different colors?",
                "date": "2023-06-05T16:20:00Z"
              },
              {
                "id": "12",
                "role": "assistant",
                "content": "Yes, the product is available in blue, red, and green.",
                "date": "2023-06-05T16:25:00Z"
              }
            ],
            "date": "2023-06-05T16:15:00Z"
          },
    // ... (additional entries)
  ];

export const IntroductionTutorialSteps: Steps = {
    "steps":[
      {
        "step": 0,
        "id": "fix-it-felix",
        "title": "Fix-it Felix",
        "description": "Fix it Felix serves as a reliable assistant, helping you identify and resolve any coding issues or create code based on your specific queries. Click on it to enable it!"
      },
      {
        "step": 1,
        "id": "question-input",
        "title": "Looking for help?",
        "description": "Whether it's a specific query or a code-related question, feel free to ask away! Just remember, the more specific and concise you are, the quicker I can find the perfect answer for you."
      },
      {
        "step": 2,
        "id": "upload-file-btn",
        "title": "Provide relevant information",
        "description": "Enhance my understanding and deliver superior responses by uploading files that offer additional context."
      }
    ]
}

export const assistantsSampleData: Assistant[] = [
    {
    
      "assistant_id": "asst_L0Dy93H2yZKk2adIBqIAEWNE",
      "name": "felix",
    
      "short_description": "I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n - Be as specific as possible when describing your problem. The more details you provide, the better equipped I'll be to assist you. \n - Keep your queries concise and to the point. This will help me pinpoint the exact information you need. \n - If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. \n - Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    },
    {
      "assistant_id": "ert4645614",
      "name": "raplh",
      
      "short_description": " I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n Be as specific as possible when describing your problem. \n\n The more details you provide, the better equipped I'll be to assist you. Keep your queries concise and to the point. This will help me pinpoint the exact information you need. If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    },
    {
     
      "assistant_id": "ert46456445",
      "name": "wendy",
      "short_description": " I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n Be as specific as possible when describing your problem. The more details you provide, the better equipped I'll be to assist you. Keep your queries concise and to the point. This will help me pinpoint the exact information you need. If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    },
    {
      "assistant_id": "ert464564135",
      "name": "karen",
      "short_description": " I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n Be as specific as possible when describing your problem. The more details you provide, the better equipped I'll be to assist you. Keep your queries concise and to the point. This will help me pinpoint the exact information you need. If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    },
    {
      "assistant_id": "ert46456413",
      "name": "karen",
      "short_description": " I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n Be as specific as possible when describing your problem. The more details you provide, the better equipped I'll be to assist you. Keep your queries concise and to the point. This will help me pinpoint the exact information you need. If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    },
    {
      "assistant_id": "ert1243464564",
      "name": "karen",
      "short_description": " I'm Felix, your trusty code partner assistant. Not only can I help you troubleshoot those errors, but I can also analyze your code to uncover any lurking issues.",
      "description": "To ensure a seamless and satisfying experience, here are a few tips to keep in mind: \n Be as specific as possible when describing your problem. The more details you provide, the better equipped I'll be to assist you. Keep your queries concise and to the point. This will help me pinpoint the exact information you need. If you have an error code handy, please share it with me. It'll aid in troubleshooting and speeding up the resolution process. Provide me with as much context as you can. Feel free to paste your code snippet along with your query for even better results. The more information I have, the more precise and tailored my assistance can be.",
      "photo_name": "bowie_homo.png"
    }
  ]
