import { useRef, useState } from "react";
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, Modal, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { SendRegular, ExpandUpRightRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { useBoolean } from "@fluentui/react-hooks";

interface Props {
    Id: string,
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ Id, onSend, disabled, placeholder, clearOnSend, conversationId }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [textmodal, setTextModal] = useState<string>("");
    const containerRef = useRef<HTMLDivElement>(null);
    const sendQuestionDisabled = disabled || !question.trim();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if(conversationId){
            onSend(question, conversationId);
        }else{
            onSend(question);
        }

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(!hideDialog){
            setTextModal(newValue|| "")
        }else{
            setQuestion(newValue || "");
        }
    };

    const onConfirmBtn = () =>{
        toggleHideDialog()
        setQuestion(textmodal)
        setTextModal("");
    }

    const openModal = () => {
        if(question.trim().length > 0){
            setTextModal(question)
        }
        toggleHideDialog()
    }
    const closeModal = () => {
        setTextModal("");
        toggleHideDialog()
    }
    return (
        <div>

            {/* Dialog */}
            <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{type: DialogType.normal, title:'Prompt editor', closeButtonAriaLabel:'Close'}}
            modalProps={{dragOptions: {moveMenuItemText: 'Move', closeMenuItemText:'Close', menu: ContextualMenu ,keepInBounds: true}}}
            minWidth='400px'
            maxWidth='800px'
            >
                <div ref={containerRef} style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    <TextField multiline rows={15} autoAdjustHeight scrollContainerRef={containerRef} value={textmodal}  onChange={onQuestionChange} className={styles.modalTextArea}/>
                </div>
                <DialogFooter>
                    <PrimaryButton style={{background: '#009ADD', border: 'none'}} onClick={onConfirmBtn} text="Confirm" />
                    <DefaultButton onClick={closeModal} text="Cancel" />
                </DialogFooter>
            </Dialog>  

        {/* Question Input component */}
        <Stack id={Id} horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            
            {/* Open modal button  */}
            <div role="button" tabIndex={1} aria-label="Open modal"
            onClick={openModal} className={styles.modalContainer}>
                <ExpandUpRightRegular  className={styles.openQuestionModal}/>
            </div>

            {/* Send button */}
            <div className={styles.questionInputSendButtonContainer} 
                role="button" 
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                { sendQuestionDisabled ? 
                    <SendRegular className={styles.questionInputSendButtonDisabled}/>
                    :
                    <img src={Send} className={styles.questionInputSendButton}/>
                }
            </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
        </div>
    );
};
