import {useContext }from "react";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./SpinnerState.module.css";
import { FontIcon, Spinner, SpinnerSize, mergeStyles } from "@fluentui/react";
import { ApplicationLoadingState } from "../../api";

const SpinnerState = () => {
    const appStateContext = useContext(AppStateContext);
    return (
     <>
        {
            
            appStateContext?.state.isApplicationLoadingState == ApplicationLoadingState.Loading ? 
                <Spinner styles={{label:{fontSize: 20}}} className={styles.customSpinner} size={SpinnerSize.large} label="Hang on tight, we're almost ready to assist you..."  ariaLive="assertive" labelPosition="top" /> 
                : 
                   <div className={styles.errorSection}>
                        <label className={styles.label500}>500</label>
                        <div className={styles.errorText}>
                            <h1>Sorry, It's not you. It's me.</h1>
                            <h2>We're experiencing an internal server problem</h2>
                            <h4>Please try again later</h4>
                        </div>
                   </div>
            
        }
     </>   
    )
}

export default SpinnerState;