import { useRef, useState, useEffect, useContext, useLayoutEffect} from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack, Icon, Pivot, PivotItem, FontIcon, IDialogContentProps, Link, MessageBar, MessageBarType, DialogFooter, PrimaryButton, DefaultButton, IDialogFooterStyleProps, IDialogFooterStyles, IPivotItemProps, CompoundButton, Coachmark, DirectionalHint, TeachingBubbleContent, TeachingBubble, MarqueeSelection, DetailsList, Selection, Spinner, SpinnerSize } from "@fluentui/react";
import { SquareRegular, ShieldLockRegular, ErrorCircleRegular, DocumentArrowUpRegular, AnimalTurtle24Filled, SpinnerIos20Filled, Warning24Filled } from "@fluentui/react-icons";
import { registerIcons } from '@fluentui/react/lib/Styling';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import { isEmpty } from "lodash-es";
import DOMPurify from 'dompurify';

import styles from "./Chat.module.css";
import FlexInterpreter from "../../assets/FlexInterpreter.png";
import Bowie from "../../assets/bowie_homo.png";
import { XSSAllowTags } from "../../constants/xssAllowTags";

import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    Conversation,
    historyGenerate,
    historyUpdate,
    historyClear,
    ChatHistoryLoadingState,
    CosmosDBStatus,
    ErrorMessage,
    frontendSettings,
    FrontendSettings,
    AssistantConversationApi,
    AssistantResponse,
    uploadFileToThreaApi,
    FileObj,
    deleteThread,
    getTutorialProgress,
    TutorialProgress,
    ApplicationLoadingState,
    getConversationHistory,
    Assistant,
    getAssistants,
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import TeachingBubbleSteps from "../../components/IntroductionFlow/TeachingBubbleSteps";
import {IntroductionTutorialSteps, assistantsSampleData} from "../../constants/chatHistory";
import TutorialDialog from "../../components/tutorialDialog/TutorialDialog";
import ChatHistory from "../../components/ChatHistory/ChatHistory";
import AssistantsCard from "../../components/Assistants/AssistantsCard";
import supersub from "remark-supersub";

registerIcons({
    icons: {
        DocumentArrowUpRegular: <DocumentArrowUpRegular />
    }
  });

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = () => {
    //Authentication State
    const { authState, oktaAuth } = useOktaAuth();
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [enableUploadFileBtn, setenableUploadFileBtn] = useState<boolean>(true);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const [isModalOpen, { toggle: toggleHideDialog }] = useBoolean(true);
    const [openDialogTutorial, { toggle: toggleHideTutorialDialog }] = useBoolean(true);
    const [isFileSuccessMessage, {setTrue: showFileSuccessMessage, setFalse: hideFileSuccessMessage}] = useBoolean(false);
    const [lastHeader, setHeader] = useState<{props: IPivotItemProps} | undefined>(undefined);
    const [fileToUpload, setFile] = useState<File | null>(null);
    const supportedFiles = ".c, .cpp, .csv, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .tar, .ts, .xlsx, .xml, .zip  (512MB, UTF-8 BOM text file)"
    const [assistantsObj, setAssistants] = useState<Assistant[]>([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.largeHeader,
        title: "Assistant files",
        closeButtonAriaLabel: 'Close',
    };
    const dialogFooterProps : IDialogFooterStyles = {
        actions:'',
        action: '',
        actionsRight: {
            justifyContent: "normal"
        }
    }
    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };
    
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }
    
    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]
    
    const closeModal = () =>{
        toggleHideDialog();
        setHeader(undefined);
    };

    //Tutorial Dialog Methods
    const showDialogTutorial = () =>{
        appStateContext?.dispatch({"type":"CLOSE_DIALOG_TUTORIAL", payload:  true})
        
    }

    //Upload file methods  
    const handleButtonClick = () =>{
        inputRef.current?.click()
    };

    const handleFileOnChnage = (event: any) => {
        const fileObject = event?.target.files && event?.target.files[0];   
        if(!fileObject){
            return;
        }
        setenableUploadFileBtn(false);
        setFile(fileObject);
        showFileSuccessMessage();
    }
    //end file methods

    // useEffect(() => {
    //     if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working  
    //         && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
    //         && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail 
    //         && hideErrorDialog) {
    //         let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
    //         setErrorMsg({
    //             title: "Chat history is not enabled",
    //             subtitle: subtitle
    //         })
    //         toggleErrorDialog();
    //     }
    // }, [appStateContext?.state.isCosmosDBAvailable]);

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    // useEffect(() => {
    //     setIsLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
    // }, [appStateContext?.state.chatHistoryLoadingState])
    useEffect(()=>{
        if(appStateContext?.state.userProfile){
            const getConvesations = async() =>{
                const accessToken = await oktaAuth.getAccessToken();
                const conversations = await getConversationHistory(appStateContext?.state.userProfile?.oktaId,5,accessToken);
                appStateContext.dispatch({type:'FETCH_CHAT_HISTORY',payload: conversations })
               
            }
            getConvesations();
        }
    },[])

    useEffect(() => {
        const getAssistantList = async() => {
            const accessToken = await oktaAuth.getAccessToken();
            const assistantList = await getAssistants(accessToken);
            if(assistantList){
                setAssistants(assistantList)
            }
            else{
                setErrorMsg({
                    title: "Assistant warning",
                    subtitle: "The assistant are not available right now. Please try again later.",
                })
            }
        }
        getAssistantList();
    },[])

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent

            if (resultMessage.context) {
                toolMessage = {
                    id: uuid(),
                    role: TOOL,
                    content: resultMessage.context,
                    date: new Date().toISOString(),
                }
            }
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const uploadFileToThread = async () => {
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        
        //If an assistant is selected and the user wants to upload a file the bot uploads the file to the assistant and creates a file_id otherwise we just do not delete the current file updated to sent to the conversation API.
        if(appStateContext?.state.assitant){
            let result = {} as FileObj;
            setenableUploadFileBtn(true);
            const accessToken = oktaAuth.getAccessToken();
            const response = await uploadFileToThreaApi(fileToUpload, appStateContext?.state.userProfile, accessToken, abortController.signal);
            
            if(response?.body){
                const reader = response.body.getReader();
    
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;
                    
                    var text  = new TextDecoder("utf-8").decode(value);
                    result = JSON.parse(text);
                }
            }
            //save the file into the context
            appStateContext?.dispatch({"type":"SET_FILE_ID", payload: result.id })
            setFile(null);
        }
        //close the modal and clear file data
        setenableUploadFileBtn(true);
        hideFileSuccessMessage();
        toggleHideDialog();
    };
   
    const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: ConversationRequest = {
            user_profile: appStateContext?.state.userProfile,
            file: fileToUpload ?? undefined,
            messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };

        let result = {} as ChatResponse;
        try {
            const accessToken = await oktaAuth.getAccessToken();
            const response = await conversationApi(request, appStateContext?.state.genAIModel, accessToken, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    setShowLoadingMessage(false);
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId);
                                    })
                                }
                                else if (result.error) {
                                    throw Error(result.error);
                                }
                                runningText = "";
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                        }
                    });
                }
                conversation.messages.push(toolMessage, assistantMessage)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, toolMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const makeApiRequestAssistant = async(question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        //Create the thread message object
        const threadMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            file_ids: appStateContext?.state.fileIds ?? [],
            date: new Date().toISOString(),
        };

        //Create the conversation history to show in the UI
        let conversation: Conversation | null | undefined;
        if(!conversationId){
            conversation = {
                id: conversationId  ?? uuid(),
                assistant_name: appStateContext?.state.assitant?.name ?? "",
                assistant_id: appStateContext?.state.assitant?.assistant_id ?? "",
                thread_id: "",
                title: question,
                messages: [threadMessage],
                date: new Date().toISOString()
            }
        }else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(threadMessage);
            }
        }

        //Update the conversation state
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: ConversationRequest = {
            conversation_id: conversation.id,
            assistant_name: conversation.assistant_name?.trim().length === 0 ? "" : conversation.assistant_name,
            assistant_id: conversation.assistant_id?.trim().length === 0 ? "" : conversation.assistant_id,
            thread_id: conversation.thread_id?.trim().length === 0 ? "" : conversation.thread_id,
            messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };

        let result = {} as AssistantResponse;

        //Make the call to the api
        try{
            const accessToken = await oktaAuth.getAccessToken();
            const response = await  AssistantConversationApi(request, appStateContext?.state.userProfile, accessToken, abortController.signal)
            if(response?.body){
                const reader = response.body.getReader();

                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;
                    
                    var text  = new TextDecoder("utf-8").decode(value);
                    result = JSON.parse(text);

                    setShowLoadingMessage(false);
                     //create message
                     const resultMessage: ChatMessage = {
                        id: uuid(),
                        role: "assistant",
                        content: result.content[0].text.value,
                        date: new Date().toISOString(),
                    };
                    processResultMessage(resultMessage,threadMessage,conversationId);
                }

       
                 conversation.thread_id = result.thread_id;
                 conversation.messages.push(toolMessage,assistantMessage);
                 appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation })
                 setMessages([...messages,toolMessage,assistantMessage]);
            }
        }catch(error){
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, threadMessage])
            }
        }finally{
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();
    }

    const makeApiRequestWithCosmosDB = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        //api call params set here (generate)
        let request: ConversationRequest;
        let conversation;
        if (conversationId) {
            conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
                request = {
                    messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
                };
            }
        } else {
            request = {
                messages: [userMessage].filter((answer) => answer.role !== ERROR)
            };
            setMessages(request.messages)
        }
        let result = {} as ChatResponse;
        try {
            const response = conversationId ? await historyGenerate(request, abortController.signal, conversationId) : await historyGenerate(request, abortController.signal);
            if (!response?.ok) {
                const responseJson = await response.json();
                var errorResponseMessage = responseJson.error === undefined ? "Please try again. If the problem persists, please contact the site administrator." : responseJson.error;
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: `There was an error generating a response. Chat history can't be saved at this time. ${errorResponseMessage}`,
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    setMessages([...messages, userMessage, errorChatMsg])
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...resultConversation.messages]);
                return;
            }
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    setShowLoadingMessage(false);
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId);
                                    })
                                }
                                runningText = "";
                            }
                            else if (result.error) {
                                throw Error(result.error);
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                         }
                    });
                }

                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                } else {
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                }
                if (!resultConversation) {
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                isEmpty(toolMessage) ?
                    setMessages([...messages, assistantMessage]) :
                    setMessages([...messages, toolMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = `An error occurred. ${errorResponseMessage}`;
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    if (!result.history_metadata) {
                        console.error("Error retrieving data.", result);
                        console.log("errorMessage", errorMessage)
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: errorMessage,
                            date: new Date().toISOString()
                        } 
                        setMessages([...messages, userMessage, errorChatMsg])
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    resultConversation.messages.push(errorChatMsg);
                }
                if (!resultConversation) {
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();

    }

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            let response = await historyClear(appStateContext?.state.currentChat.id)
            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        setClearingChat(false)
    };

    const newChat = async() => {
        setClearingChat(true)

        if(appStateContext?.state.currentChat?.thread_id){
            const accessToken = await oktaAuth.getAccessToken();
            let response = await deleteThread(appStateContext?.state.currentChat?.thread_id, accessToken)
            if (!response.ok) {
                setErrorMsg({
                    title: "Error deleting thread",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            }else{
                setProcessMessages(messageStatus.Processing)
                setMessages([])
                setIsCitationPanelOpen(false);
                setActiveCitation(undefined);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
                appStateContext?.dispatch({type: 'DELETE_ASSISTANT'});
                appStateContext?.dispatch({type: 'DELETE_FILES'});
                setProcessMessages(messageStatus.Done)
            }
        }else{
            setProcessMessages(messageStatus.Processing)
            setMessages([])
            setIsCitationPanelOpen(false);
            setActiveCitation(undefined);
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
            appStateContext?.dispatch({type: 'DELETE_ASSISTANT'});
            appStateContext?.dispatch({type: 'DELETE_FILES'});
            setProcessMessages(messageStatus.Done)
            
        }
        setClearingChat(false)
    };
    const uploadFile = () =>{
        toggleHideDialog();
    }

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);


    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation(citation);
        setIsCitationPanelOpen(true);
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat
    }

    return (
        <div className={styles.container} role="main">
            {(
                
                <Stack horizontal className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {
                        !messages || messages.length < 1 ? (
                            appStateContext?.state.assitant != null ?   

                            <div className={styles.chatEmptyState}> 
                                {appStateContext?.state.assitant &&
                                    <div className={styles.assitantBox}>
                                        <div>
                                            <ReactMarkdown children={appStateContext?.state.assitant?.short_description} remarkPlugins={[remarkGfm, supersub]}/>
                                        </div>
                                        <div>
                                            <ReactMarkdown children={appStateContext?.state.assitant.description} 
                                            remarkPlugins={[remarkGfm, supersub]} />
                                        </div>
                                    </div>
                                }
                            </div> 
                            :
                            <Stack className={styles.chatEmptyState}>
                                <h1 className={styles.chatEmptyStateTitle}>{ui?.chat_title ?? `Code Agent`}</h1>
                                <h2 className={styles.chatEmptyStateSubtitle}>{ui?.chat_description}</h2>
                                <p>Not sure what I can do? <Link onClick={showDialogTutorial}>Click here</Link> </p>
                                
                                {
                                    assistantsObj && <AssistantsCard assistants={assistantsObj}/>
                                }
                
                                {
                                   appStateContext?.state.showdialogTutorial && (<TutorialDialog />
                                )}                                                                
                            </Stack> 
                        ) : (
                            //Here is the actual message history.
                            <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log"> 
                                {messages.map((answer, index) => (
                                    <>
                                        {answer.role === "user" ? (
                                            <div className={styles.chatMessageUser} tabIndex={0}>
                                                <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                            </div>
                                        ) : (
                                            answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    answer={{
                                                        answer: answer.content,
                                                        citations: parseCitationFromMessage(messages[index - 1]),
                                                        message_id: answer.id,
                                                        feedback: answer.feedback
                                                    }}
                                                    onCitationClicked={c => onShowCitation(c)}
                                                />
                                            </div> : answer.role === ERROR ? <div className={styles.chatMessageError}>
                                                <Stack horizontal className={styles.chatMessageErrorContent}>
                                                    <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                    <span>Error</span>
                                                </Stack>
                                                <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                            </div> : null
                                        )}
                                    </>
                                ))}
                                {showLoadingMessage && (
                                    <>
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer: "Generating answer...",
                                                    citations: []
                                                }}
                                                onCitationClicked={() => null}
                                            />
                                        </div>
                                    </>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <Stack horizontal className={styles.chatInput}>
                            <Stack>
                                {/* Add documents to the conversation */}
                                <CommandBarButton
                                    id="upload-file-btn"
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#BDBDBD !important"
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)"
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={styles.newChatIcon}
                                    iconProps={{iconName:'DocumentArrowUpRegular'}}
                                    onClick={uploadFile}
                                    aria-label="upload a file"
                                />
                                <Dialog
                                    hidden={isModalOpen}
                                    dialogContentProps={dialogContentProps}
                                    minWidth={550}
                                >
                                    <Stack>
                                        <span className={styles.dialogHeader}> Choose and existing file uploaded earlier or upload a new file.</span>
                                        <Pivot onLinkClick={setHeader}>
                                            <PivotItem headerText="Upload local files">
                                                <Stack enableScopedSelectors verticalAlign="center" className={styles.mainStack}>
                                                    <Stack.Item className={styles.itemStack}>
                                                        <FontIcon aria-label="Upload file" iconName="CloudUpload" className={styles.cloudIcon}/>
                                                    </Stack.Item>
                                                    <Stack.Item className={styles.itemStack}>
                                                        <Link underline onClick={handleButtonClick}>Browse a file</Link>
                                                        <input hidden type="file" accept=".c, .cpp, .csv, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .tar, .ts, .xlsx, .xml, .zip" ref={inputRef} onChange={handleFileOnChnage} />
                                                    </Stack.Item>
                                                    <Stack.Item className={styles.itemStack}>
                                                        <span className={styles.supportedFiles}> {supportedFiles}</span>
                                                    </Stack.Item>
                                                </Stack>
                                                { isFileSuccessMessage &&
                                                <MessageBar
                                                    messageBarType={MessageBarType.success}
                                                    isMultiline={false}
                                                    onDismiss={hideFileSuccessMessage}
                                                >{fileToUpload?.name}</MessageBar>
                                                }
                                            </PivotItem>
                                            <PivotItem headerText="Choose from existing files">
                                                <Stack enableScopedSelectors verticalAlign="center" className={styles.mainStack} >
                                                    <Stack.Item className={styles.itemStack}>
                                                        <h3>Under Construction</h3>
                                                    </Stack.Item>
                                                    <Stack.Item className={styles.itemStack}>
                                                        <Warning24Filled/>
                                                    </Stack.Item>
                                                </Stack>
                                            </PivotItem>

                                        </Pivot>
                                    </Stack>
                                    {lastHeader?.props.headerText === 'Upload local files' || lastHeader?.props.headerText == undefined?                                         
                                    <DialogFooter styles={dialogFooterProps} className="">
                                        <PrimaryButton text="Upload file" onClick={uploadFileToThread} disabled={enableUploadFileBtn}/>
                                        <DefaultButton text="Cancel" onClick={closeModal}/>
                                        </DialogFooter> : (
                                        <DialogFooter styles={dialogFooterProps} className="">
                                            <PrimaryButton text="Select file" disabled={true}/>
                                            <DefaultButton text="Cancel" onClick={closeModal}/>
                                        </DialogFooter>

                                    )}
                                </Dialog>
                                {/* Clear chat */}
                                {/* <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#BDBDBD !important",
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #318378 33.63%, #429287 70.31%, #67baaf 100%)",
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={styles.chatCodeBtn}
                                    iconProps={{ iconName: 'Code' }}
                                    aria-label="chat code button"
                                /> */}
                                <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#cccccc !important",
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={ styles.clearChatBroom }
                                    iconProps={{ iconName: 'Broom' }}
                                    onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? clearChat : newChat}
                                    disabled={disabledButton()}
                                    aria-label="clear chat button"
                                />
                                <Dialog
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                >
                                </Dialog>
                            </Stack>

                            {appStateContext?.state.showTutorial && (
                                <TeachingBubbleSteps/>
                            
                            )}
                            <QuestionInput
                                Id="question-input"
                                clearOnSend
                                placeholder="Type a new question..."
                                disabled={isLoading}
                                onSend={(question, id) => {
                                   appStateContext?.state.assitant != null ? makeApiRequestAssistant(question, id) : makeApiRequestWithoutCosmosDB(question,id)
                                }}
                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                            />
                        </Stack>
                    </div>
                    {/* Citation Panel */}
                    {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                            <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                <span aria-label="Citations" className={styles.citationPanelHeader}>Citations</span>
                                <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
                            </Stack>
                            <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
                            <div tabIndex={0}>
                                <ReactMarkdown
                                    linkTarget="_blank"
                                    className={styles.citationPanelContent}
                                    children={DOMPurify.sanitize(activeCitation.content, {ALLOWED_TAGS: XSSAllowTags})}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                        </Stack.Item>
                    )}
                    {(appStateContext?.state.isChatHistoryOpen) && <ChatHistory/>}
                </Stack>
            )}
        </div>
    );
};

export default Chat;
