import { ActivityItem, Panel, PanelType, Spinner, Stack, Toggle } from '@fluentui/react';
import * as React from 'react';
import styles from './ChatHistory.module.css';
import { AppStateContext } from '../../state/AppProvider';
import { GenAIModels, deleteThread } from '../../api';
import { useOktaAuth } from "@okta/okta-react";

function ChatHistory() {

    const { authState, oktaAuth } = useOktaAuth();
    const appStateContext = React.useContext(AppStateContext)
    const conversationHistory = appStateContext?.state.chatHistory 
    const currentGenAIModel = appStateContext?.state.genAIModel
    const [activeToggle, setActiveToggle] = React.useState<GenAIModels>();  
    const toggles = [  
      { id: GenAIModels.GPT35 , label: "GPT-3.5 Turbo" },  
      { id: GenAIModels.GPT4, label: "GPT-4" },  
      { id: GenAIModels.GPT4o, label: "GPT-4o" },  
      { id: GenAIModels.DALLE, label: "DALL-E" }
    ]; 

    const closeModal = () => {
      appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    }

    const setCurrentConversation = async(id: string) => {
        // user has current conversation so clear it before set the other.
         if(appStateContext?.state.currentChat != null && appStateContext.state.currentChat != undefined){
           const accessToken = oktaAuth.getAccessToken();
           let response = await deleteThread(appStateContext.state.currentChat?.thread_id, accessToken);
           if(!response.ok)
              return;
           appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT',payload: null});
         }
         //Set conversation to selected conversation
         const conversation = appStateContext?.state.chatHistory?.filter(item => item.id == id);
         if(conversation != undefined)
          appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT',payload: conversation[0]});

         appStateContext?.dispatch({type:'TOGGLE_CHAT_HISTORY'});
    }

    const handleToggleChange = (id: GenAIModels) => {  
      setActiveToggle(id);  
      appStateContext?.dispatch({type:'SET_GENAI_MODEL',payload: id})
      console.log(id);
    };    

    React.useEffect(() => {
       setActiveToggle(currentGenAIModel);
    },[])

    return (
        <Panel
        type={PanelType.smallFixedNear}
        isOpen={appStateContext?.state.isChatHistoryOpen}
        onDismiss={closeModal}
        closeButtonAriaLabel="Close"
      >
        {/* <Stack tokens={{childrenGap: 'm', padding: 'm'}} aria-label="chat history">
          <h3 style={{textAlign: 'center'}}>Select GenAI model</h3>
          {
          toggles.map((toggle) => (  
            <Toggle  
              key={toggle.id}  
              label={toggle.label}  
              inlineLabel
              checked={activeToggle === toggle.id}  
              onChange={() => handleToggleChange(toggle.id)}  
            />  
          ))
          }  
        </Stack> */}
        <Stack className={styles.chatCardParent} tokens={{childrenGap: 'm', padding: 'm'}} aria-label="chat history">
          <h3>Conversation history</h3>
          {
            (conversationHistory && conversationHistory.length > 0) ? conversationHistory.map((item) => (
              <Stack.Item className={styles.chatCard} onClick={() =>setCurrentConversation(item.id)}>
                <h4>{item.messages[0].content && (item.messages[0].content.length > 20) ? `${item.messages[0].content.substring(0,20)}...` : item.messages[0].content}</h4>
                <p className={styles.chatContent}>{`${item.messages[1].content.substring(0,40)}...`}</p>
                <label className={styles.conversationDate}>{new Date(item.date+' UTC').toString().substring(0,25)}</label>
              </Stack.Item>
            )) : 
              <div>
                {(conversationHistory && conversationHistory?.length <= 0) ? <h3>Start chatting. No conversations.</h3> :<Spinner label="Loading conversations..." ariaLive="assertive" labelPosition="top" />}
              </div>
          }
        </Stack>
      </Panel>
      );
}
export default ChatHistory;