import { useRef, useState, useEffect, useContext, useLayoutEffect} from "react";

import styles from "./Assistants.module.css";
import {IntroductionTutorialSteps, assistantsSampleData} from "../../constants/chatHistory";
import { AppStateContext } from "../../state/AppProvider";
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack } from "@fluentui/react";

import { Assistant, } from "../../api";
import { useBoolean } from "@fluentui/react-hooks";
import ReactMarkdown from "react-markdown";
import supersub from "remark-supersub";
import remarkGfm from "remark-gfm";
import DOMPurify from "dompurify";

interface Props {
    assistants: Assistant[]
}

const AssistantsCard = ({assistants}: Props) => {
    const appStateContext = useContext(AppStateContext)
    const [filteredAssistant, setFilteredAssistant] = useState<Assistant | null>(null);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const onClickCard = (name: string) => {
        const assistant = assistants.filter(a => a.name === name);
        const sanitizedText = DOMPurify.sanitize(assistant[0].description);
        assistant[0].description = sanitizedText.replace(/\\n/g, '\n');
        setFilteredAssistant(assistant[0]);
        toggleHideDialog();
    }

    const onConfirm = () => {
        appStateContext?.dispatch({type: 'SET_ASSISTANT',payload: filteredAssistant})
        toggleHideDialog()
    }
    
    return (
        <>
        <Stack horizontal horizontalAlign="center" wrap>
            {( assistants.map((assistant) => (
            <Stack horizontal style={{maxWidth:'300px', background: '#eaeef4ff', gap: "10px",margin:"8px",padding: "10px", borderRadius: '8px'}} className={styles.assistantContainer}  onClick={() =>onClickCard(assistant.name)}>
                <Stack.Item align="center">
                    <img src={`/static/${assistant.photo_name}`} className={styles.assistanIcon} aria-hidden="true"/>
                </Stack.Item>
                <Stack.Item>
                        <p className={styles.assistantName}>{assistant.name}</p>
                        <p className={styles.assistantDesc}>{assistant.short_description}</p>
                </Stack.Item>
            </Stack>                        
            ))
            )}
        </Stack>
        
        {/* Modal to show assistant info */}
        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{type: DialogType.normal, title: filteredAssistant?.name, closeButtonAriaLabel:'Close'}}
            modalProps={{dragOptions: {moveMenuItemText: 'Move', closeMenuItemText:'Close', menu: ContextualMenu ,keepInBounds: true}}}
            minWidth='400px'
            maxWidth='600px'
      >
        <Stack>
            <Stack.Item>
                <ReactMarkdown children={filteredAssistant?.short_description ?? ""} remarkPlugins={[remarkGfm, supersub]}/>
            </Stack.Item>
            <Stack.Item>
                <ReactMarkdown children={`${filteredAssistant?.description}` ?? ""} remarkPlugins={[remarkGfm, supersub]} key={filteredAssistant?.description }/>
            </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton style={{background: '#009ADD', border: 'none'}} onClick={onConfirm} text="Confirm" />
          <DefaultButton onClick={toggleHideDialog} text="Close" />
        </DialogFooter>
        </Dialog>
        </>
    );
}

export default AssistantsCard