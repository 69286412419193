import { DefaultButton, Dialog, DialogFooter, FontIcon, IDialogFooterStyles, Link, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Warning24Filled } from "@fluentui/react-icons";
import { useRef, useState, useEffect, useContext, useLayoutEffect} from "react";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./TutorialDialog.module.css";
const TutorialDialog = () => {

    const appStateContext = useContext(AppStateContext);
    const [dialogVisible, { toggle: DialogVisible }] = useBoolean(true);
    
    const dialogFooterProps : IDialogFooterStyles = {
        actions:'',
        action: '',
        actionsRight: {
            justifyContent: "normal"
        }
    }
    const closeModal = () =>{
        DialogVisible();
        appStateContext?.dispatch({"type":"CLOSE_DIALOG_TUTORIAL", payload: false })
    }

    return (
        <Dialog
        hidden={!appStateContext?.state.showdialogTutorial}
        onDismiss={DialogVisible}
        minWidth={550}
    >
        <Stack>
            <h3 className={styles.title} >Here are some instructions of how you can use Flex Code.</h3>
            <Pivot>
                <PivotItem headerText="General queries - Insert code fragment">
                    <Stack enableScopedSelectors verticalAlign="center" >
                        <Stack.Item className={styles.item}>
                            <ol className={styles.ol}>
                                <li>Clearly articulate your question or request for information, making sure to include all relevant details.</li>
                                <li>If your question involves code, provide the code snippet or instructions after stating your question.</li>
                                <li>After formulating your question and including any necessary code, press the "Enter" key on your keyboard to send the question.</li>
                                <li>Wait for a response from Flex Code, which will provide you with the information or guidance you seek.</li>
                            </ol> 
                        </Stack.Item>   
                    </Stack>
                </PivotItem>
                <PivotItem headerText="Ask with context">
                <Stack enableScopedSelectors verticalAlign="center" >
                        <Stack.Item className={styles.item}>
                            <ol className={styles.ol}>
                                <li>Locate and click on the attach button in the user interface.</li>
                                <ol>
                                    <li>From the options presented, select "upload local files"</li>
                                    <li>A file modal window will appear. Click on "browse a file" to open your computer's file explorer.</li>
                                    <li>Navigate to the file you wish to upload and select it.</li>
                                    <li>Once the file is selected, click on the "Upload File" button to begin the uploading process.</li>
                                </ol>
                                <li>After the file has been uploaded, proceed to formulate your question. Make sure to provide as much detail as possible.</li>
                                <li>When you have finished formulating your question, press the "Enter" key on your keyboard to send the question.</li>
                                <li>Wait for a response from Flex Code, which will provide you with the information or guidance you seek.</li>
                            </ol> 
                        </Stack.Item>   
                    </Stack>
                </PivotItem>
            </Pivot>
        </Stack>                                     
        <DialogFooter styles={dialogFooterProps} className="">
            <DefaultButton text="Close" onClick={closeModal}/>
        </DialogFooter> 
    </Dialog>
                
    );
};

export default TutorialDialog;