import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, UserProfile, AssistantFileList, TutorialProgress, Assistant, GenAIModels } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";

export async function conversationApi(options: ConversationRequest, deploymentName: GenAIModels | undefined, accessToken: string|undefined, abortSignal: AbortSignal): Promise<Response> {
    const formData = new FormData();
    if (options.file) {
        formData.append("file", options.file, options.file.name);
    }

    formData.append("messages", JSON.stringify(options.messages));
    formData.append("deploymentName", deploymentName ? JSON.stringify(deploymentName) : "");
    formData.append("user", JSON.stringify(options.user_profile));
    
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        body: formData,
        signal: abortSignal
    });

    console.log("response", response);
    return response;
}


export async function AssistantConversationApi(options: ConversationRequest, userProfile: UserProfile | undefined, accessToken: string | undefined, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/assistant", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            assistant_name: options.assistant_name,
            assistant_id: options.assistant_id,
            conversation_id: options.conversation_id,
            thread_id: options.thread_id,
            messages: options.messages,
            user: userProfile
        }),
        signal: abortSignal
    });
    return response;
}

export async function uploadFileToThreaApi(file: File | null,userProfile: UserProfile | undefined,accessToken: string|undefined, abortSignal: AbortSignal): Promise<Response> {
    
    const formData = new FormData();
    //formData.append("userProfile",JSON.stringify(userProfile));
    
    if(file){
        formData.append("file",file,file?.name);
    }

    const response = await fetch("/uploadFile", {
        method: "POST",
        headers:{
            "Authorization": `Bearer ${accessToken}`,
        },
        body: formData,
        signal: abortSignal
    });
    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}

export async function saveUserProfile(userProfile: UserProfile | undefined,accessToken: string|undefined): Promise<Response|undefined> {
    const response = await fetch("/saveUserProfile", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: userProfile
        })
    })

    if(!response.ok){
        return;
    }
    const result = await response.json();
    return result;
}

export async function getTutorialProgress(userProfile: UserProfile | undefined, accessToken: string |undefined): Promise<TutorialProgress | undefined> {
    const response = await fetch("/tutorial",{
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: userProfile
        })
    });

    if(!response.ok){
        return;
    }
    const payload = await response.json();
    return payload;
}

export async function setTutorialProgress(userProfile: UserProfile | undefined, accessToken: string | undefined): Promise<TutorialProgress|undefined> {
    const response = await fetch("/tutorial/update",{
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: userProfile
        })
    });
    if(!response.ok){
        return;
    }
    const result = await response.json()
    return result;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const getAssistants = async(accessToken: string|undefined): Promise<Assistant[]| null> => {
    const response = await fetch(`/assistant/list`,{
        "method": "GET",
        headers:{
            "Authorization": `Bearer ${accessToken}`,
        }
    });
    if(!response.ok){
        return null;
    }
    const result = await response.json() as Assistant[]
    return result;
}  

export const getConversationHistory = async (oktaId: string| undefined, rows=5, accessToken: string|undefined): Promise<Conversation[] | null> => {
    const response = await fetch(`/conversation/history?rows=${rows}&oktaId=${oktaId}`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    });
    if(!response.ok){
        return null;
    }

    const result = await response.json() as Conversation[];   
    return result;
}

export const historyList = async (offset=0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            let convMessages: ChatMessage[] = [];
            convMessages = await historyRead(conv.id)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.error("error fetching messages: ", err)
                return []
            })
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: convMessages
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then(async (res) => {
        if(!res){
            return []
        }
        const payload = await res.json();
        let messages: ChatMessage[] = [];
        if(payload?.messages){
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    feedback: msg.feedback ?? undefined
                }
                messages.push(message)
            });
        }
        return messages;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return []
    })
    return response
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;
    if(convId){
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages
        })
    }else{
        body = JSON.stringify({
            messages: options.messages
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return new Response;
    })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}

export const historyDelete = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyDeleteAll = async () : Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyClear = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyRename = async (convId: string, title: string) : Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.message){
            formattedResponse = CosmosDBStatus.Working
        }else{
            if(res.status === 500){
                formattedResponse = CosmosDBStatus.NotWorking
            }else if(res.status === 401){
                formattedResponse = CosmosDBStatus.InvalidCredentials    
            }else if(res.status === 422){ 
                formattedResponse = respJson.error    
            }else{
                formattedResponse = CosmosDBStatus.NotConfigured
            }
        }
        if(!res.ok){
            return {
                cosmosDB: false,
                status: formattedResponse
            }
        }else{
            return {
                cosmosDB: true,
                status: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err
        }
    })
    return response;
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue logging feedback.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const assistanFileList = async() : Promise<AssistantFileList> => {
    const response = await fetch("/files", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an error fetching assitants files");
        return null
    })
    return response
}

export const deleteThread = async(thread_id: string| undefined, accessToken: string | undefined): Promise<Response> => {
    const response = await fetch("/deleteThread",{
        method: "DELETE",
        body: JSON.stringify({
            thread_id: thread_id
        }),
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
    }).then((res) =>{
        return res
    }).catch((err) => {
        console.error("There was an error");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}