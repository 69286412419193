import { Outlet, Link, Await } from "react-router-dom";
import styles from "./Layout.module.css";
import FlexInterpreter from "../../assets/FlexInterpreter.png";
import { CopyRegular, ShareRegular, PersonCircle32Filled,PersonCircle24Filled } from "@fluentui/react-icons";
import { Dialog, Stack, TextField, ICommandBarStyles, IButtonStyles, DefaultButton, IconButton, FontIcon, mergeStyles, Spinner, SpinnerSize } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { ApplicationLoadingState, CosmosDBStatus, TutorialProgress, UserProfile, getTutorialProgress, saveUserProfile } from "../../api";
import logo from "../../assets/flex-white.png"
//Okta Config
import { useOktaAuth } from "@okta/okta-react";
import SpinnerState from "../../components/common/SpinnerState";

const Layout = () => {
    //State for Okta
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserProfile>();
    const handleLogin = () => oktaAuth.signInWithRedirect();
    
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;



    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);
    
    useEffect(()=>{
        const getProfileAsync = async() =>{
            if(!authState || !authState?.isAuthenticated)
                setUserInfo(undefined);
            else
            {
                try {
                    const userProfileInfo = await oktaAuth.getUser();
                    setUserInfo({oktaId: userProfileInfo.sub ,userName: userProfileInfo.name, name: userProfileInfo.given_name, lastName: userProfileInfo.family_name, email: userProfileInfo.preferred_username, country: userProfileInfo.locale });
                }catch(error){
                    return;
                }
                return userInfo;
            };
        }
        getProfileAsync();
    },[authState?.isAuthenticated]);
    
    useEffect(()=>{
         //dispatch the action to store the user info in the context
        appStateContext?.dispatch({type: 'SET_USER_INFO', payload: userInfo});

        const tutorialProgress = async(accessToken: string|undefined): Promise<TutorialProgress|undefined> => {
                if(userInfo){
                    const response = await getTutorialProgress(userInfo,accessToken);
                    return response;
                }
        };
              
        //here we need to send the call to get the info for the data base
        const getUserInfoDb = async() => {
            if(userInfo){
                const accessToken = await oktaAuth.getAccessToken();
                saveUserProfile(userInfo,accessToken).then((res)=>{
                    if(res){
                       tutorialProgress(accessToken).then((response =>{
                        if(response?.status){
                            appStateContext?.dispatch({type: 'SHOW_TUTORIAL'})
                        }
                        appStateContext?.dispatch({type: 'UPDATE_APPLICATION_LOADING_STATE', payload: ApplicationLoadingState.Success})
                       }
                    )).catch((error)=>{
                        appStateContext?.dispatch({type: 'UPDATE_APPLICATION_LOADING_STATE', payload: ApplicationLoadingState.Fail})
                        console.log("something went wrong!")
                    })
                    }else{
                        appStateContext?.dispatch({type: 'UPDATE_APPLICATION_LOADING_STATE', payload: ApplicationLoadingState.Fail})
                    }
                }).catch((error)=>{
                    appStateContext?.dispatch({type: 'UPDATE_APPLICATION_LOADING_STATE', payload: ApplicationLoadingState.Fail})
                    console.log("something went wrong!")
                })
            }
        };
        getUserInfoDb();
        //insertUserToDB();
    },[userInfo]);
 

    const iconClass = mergeStyles({
        fontSize: '2rem',
    })
    return (
        <div className={styles.layout}>
            <header role={"banner"} style={{background: '#005486', color: "#ffffff"}}>
                    
                {  !authState || !authState?.isAuthenticated ? (<></>):
                    <>
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                            <Stack horizontal verticalAlign="center" style={{height: 60+'px'}} 
                            className={styles.headerLeft}>
                                <button className={styles.hamburgerMenu}  onClick={handleHistoryClick}>
                                    <span>
                                        <FontIcon aria-label="Menu" iconName="CollapseMenu" className={iconClass}/>
                                    </span>

                                </button>

                                <img src={logo} className={styles.chatIcon}/>
                            </Stack>                    
                            <Stack horizontal verticalAlign="center" style={{height: 60+'px'}} className={styles.headerRight}>
                                    <PersonCircle24Filled/>
                                    <h2 className={styles.userName}>{appStateContext?.state.userProfile?.userName}</h2>
                            </Stack>
                        </Stack>
                       </>

                      }
            </header >
            {
                !authState || !authState?.isAuthenticated ? (
                    <Stack enableScopedSelectors  className={styles.loginContainer}>
                        <Stack.Item grow className={styles.stacktItemContent}>
                            <img src={logo} className={styles.chatIcon}/>
                            <h1>Welcome to Flex Code</h1>
                            <h2>Log in with your Okta account to continue</h2>
                            <DefaultButton text="Log in" onClick={handleLogin} className={styles.loginBtn} />
                        </Stack.Item>
                    </Stack>
                ) : (
                    appStateContext?.state.isApplicationLoadingState === ApplicationLoadingState.Loading  ||  appStateContext?.state.isApplicationLoadingState === ApplicationLoadingState.Fail ? <SpinnerState/> :
                    <Outlet />
                )
             
            }

        </div>
    );
};

export default Layout;
