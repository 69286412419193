import { IIconProps, IconButton } from "@fluentui/react";
import React, { useState } from "react";

import styles from './CommonStyles.module.css'

export default function CodeCopyBtn({ children}: any) {
    const [iconName, setIconName] = useState("Copy");
    const copyIcon: IIconProps ={
        iconName: iconName
    }

    const handleClick = (e: any) => {
        navigator.clipboard.writeText(children[0].props.children[0]);
        

        setIconName("Paste")
        setTimeout(() => {
            setIconName("Copy");
        }, 500);
    }

    return (
        <div className={styles.copyCodeButton}>
             <IconButton iconProps={copyIcon} title="Copy" ariaLabel="Copy" onClick={handleClick}  className={styles.btnElement}/>
        </div>
    )
}